<template>
  <div>
    <ActivationAlert :inline="true" :centered="windowSize?.width?.value < 768" />
    <TopCategoryMenu />
    <CarouselBanner type="main" />
    <ProductPanel />
    <CategoryPanel />
    <ContentPanel prefix="news" :subtype="PRODUCT_SUBTYPE.nmain" :base-route-code="ROUTES.CODE.NEWS" :content-type="CONTENT_TYPE.NEWS" sort="order" />
    <MobileAppPanel />
    <ContentPanel prefix="content" :subtype="PRODUCT_SUBTYPE.imain" :base-route-code="ROUTES.CODE.INSPIRATION" :content-type="CONTENT_TYPE.INSPIRATION" />
    <ReferencePanel prefix="reference" :subtype="PRODUCT_SUBTYPE.rmain" :base-route-code="ROUTES.CODE.ADS" :content-type="CONTENT_TYPE.REFERENCE" />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { sitePages } from "@settings/site-pages.settings";
import { useConfigStore } from '@stores/config';
import ActivationAlert from "@components/profile/activation-alert";
import TopCategoryMenu from "@components/category/top-category-menu";
import CarouselBanner from "@components/common/carousel-banner";
import ContentPanel from "@components/content/content-panel/content-panel";
import CategoryPanel from "@components/category/category-panel";
import ProductPanel from "@components/product/product-panel/product-panel";
import MobileAppPanel from "@components/mobile-app/mobile-app-panel";
import ReferencePanel from "@components/reference/reference-panel/reference-panel";

definePageMeta({
  layout: 'home-layout'
});

defineI18nRoute({
  paths: {
    cs: '/',
    en: '/',
    sk: '/',
    pl: '/',
    de: '/'
  }
});

const windowSize = useWindowSize();
const config = useRuntimeConfig();
const canonical = config.public.appBaseUrl + translatePath(ROUTES.CODE.HOME);

const configs = useConfigStore();

useHead({ link: [ { rel: 'canonical', href: canonical } ] });
useSeoMeta({
  title: configs.title || `${sitePages[config.public.layoutDesign].title}`,
  ogTitle: configs.title || `${sitePages[config.public.layoutDesign].title}`,
  description: configs.description || `${sitePages[config.public.layoutDesign].description}`,
  ogDescription: configs.description || `${sitePages[config.public.layoutDesign].description}`,
  ogImage: `${config.public.appBaseUrl}${sitePages[config.public.layoutDesign].image}`,
  ogType: `${sitePages[config.public.layoutDesign].type}`,
  ogUrl: canonical
});

</script>
